import React, { useState, useRef } from "react"
import ReactPlayer from "react-player"
import { navigate } from "gatsby"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import sha256 from 'js-sha256'
// components
import Actions from "./Actions"

// assets
import CircleProgress from "../../images/loading.svg"
import { BsArrowsAngleExpand } from "react-icons/bs"

let initailPlay = false

const VideoPlayer = ({
  url,
  link,
  preview,
  overlay,
  center,
  to,
  hasFullScreen = false,
}) => {
  const handle = useFullScreenHandle()
  const playerRef = useRef()

  const [isPlaying, setIsPlaying] = useState(false)
  const [buffered, setBuffered] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const togglePlay = () => {
    setIsPlaying(!isPlaying)

    if (!initailPlay) initailPlay = true
  }

  const handleSeek = _seek => {
    let timeStamp = playerRef.current.getCurrentTime()
    timeStamp += _seek

    if (timeStamp > 0 && timeStamp <= playerRef.current.getDuration())
      playerRef.current.seekTo(timeStamp)
  }

  const seekToStart = () => {
    playerRef.current.seekTo(0)
  }

  const seetToEnd = () => {
    playerRef.current.seekTo(playerRef.current.getDuration() - 1)
  }

  const handleFullScreen = () => {
    if (!isFullScreen) {
      setIsFullScreen(true)
      handle.enter()
    } else {
      handle.exit()
    }
  }


  const token = sha256("31e42e6e-13e8-4fe7-a4aa-060c91006e49" + url + 1623440202)
  // ?token=${token}&expires=${1623440202}
  const bunnyUrl = `https://iframe.mediadelivery.net/embed/65143/${url}?autoplay=false`
  console.log(bunnyUrl)
  return (
    <React.Fragment>
        <div
          style={{ paddingTop: "56.25%", position: "relative" }}
        >
          <iframe src={bunnyUrl}
            loading="lazy" style={{ border: "none", position: "absolute", top: "0", height: "100%", width: "100%" }}
            allow="accelerometer; gyroscope; encrypted-media; picture-in-picture; autoplay: false" allowfullscreen="true">
          </iframe>
          {buffered && (
            <img
              src={CircleProgress}
              alt="bufferring"
              style={{
                width: "64px",
                height: "64px",
                position: "absolute",
                zIndex: 999,
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%)`,
              }}
            />
          )}
          {link && (
            <div
              className="absolute inset-0  z-10"
              onClick={() => navigate(link)}
            />
          )}
        </div>
    </React.Fragment>
  )
}

export default VideoPlayer
